<template>
  <section >
    <div>

        <div  >
          <div class="row">
            <div class="flex xs12">
              <va-card :title="$t('forms.inputs.title')">
                <va-card-content>
                  <div class="flex" v-if="btns ==1">
                        <va-button class="mr-2 mb-2"  @click="teste()"> {{ $t('PDF') }}</va-button>
                        <va-button class="mr-2 mb-2" color="danger" @click="this.$router.push({name: 'planejamentoPedaAll'});">Voltar</va-button>
                  </div>
                  <form id="print-pagina">
                    <div class="row" style="margin-left:20px;">

                      <div class="col-md-12 col-12">
                        <div>
                            <p style="font-size: 22px;padding-top: 60px;color: #5c5c5c;font-weight: 600;">
                                {{planejamento.segmento}} - {{planejamento.serie}} <br>

                                {{planejamento.disciplina}}  <br>
                            </p>
                        </div>
                      </div>

                      <div class="col-md-12 col-12">

                        <div>
                            <div>
                                <div  style="margin-bottom: 20px;font-size: 18px;font-weight: 700;">
                                    1º Periodo
                                </div>
                                <div >
                                    <blockquote  v-html="planejamento.periodo1_conteudo">
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div>
                            <div >
                                <div  style="margin-bottom: 20px;font-size: 18px;font-weight: 700;">
                                    2º Periodo
                                </div>
                                <div >
                                    <blockquote  v-html="planejamento.periodo2_conteudo">
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div >
                            <div >
                                <div  style="margin-bottom: 20px;font-size: 18px;font-weight: 700;">
                                    3º Periodo
                                </div>
                                <div  >
                                    <blockquote  v-html="planejamento.periodo3_conteudo">
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div >
                            <div >
                                <div  style="margin-bottom: 20px;font-size: 18px;font-weight: 700;">
                                    4º Periodo
                                </div>
                                <div class="card-body" >
                                    <blockquote  v-html="planejamento.periodo4_conteudo">
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </form>

                </va-card-content>
              </va-card>
            </div>
          </div>

        </div>

    </div>
  </section >
</template>

<script>

import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { SeriesEscolar } from "@/class/serie";
import { DisciplinaEscolar } from "@/class/disciplina";

import { jsPDF } from "jspdf";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  props: {
    id:{
    },
  },
  components: {
     QuillEditor
  },
  name: 'planejamentopedagogico-cadastrar',
  data () {
    return {
      planejamento: {
        segmento_id:0,
        segmento:null,
        serie_id:0,
        serie:null,
        disciplina_id:0,
        disciplina:null,
        ano:null,
        mes: null,
        dia: null,
        conteudo: null,
        periodo1_conteudo:null,
        periodo2_conteudo:null,
        periodo3_conteudo: null,
        periodo4_conteudo:null,
      },
      datet:null,
      isLoading: false,
      segmentosALL:[],
      serieALL:[],
      disciplinaALL:[],
      nome_periodos:1,
      btns:1,
    }
  },
  methods: {
    async teste(){

      const doc = new jsPDF('l', 'pt', 'a4');
      const p1 = document.querySelector("#print-pagina");


      await doc.html(p1,{x:10,y:0});
      doc.setFontSize(9);
      doc.output("dataurlnewwindow");




      let item = " <div class='row'><div class='col-md-2 col-3'> <div ><p style='font-size: 22px;padding-top: 60px;text-align: center;color: #5c5c5c;font-weight: 600;'>"+this.planejamento.segmento+"<br>"+this.planejamento.serie+"  <br>"+this.planejamento.disciplina+"<br></p></div></div><div class='col-md-10 col-9'><div ><div class='card' style='box-shadow: 0 4px 70px -18px #7070702e !important;'><div class='card-header'>1º Periodo</div><div class='card-body' ><blockquote class='blockquote mb-0'> "+this.planejamento.periodo1_conteudo+"</blockquote></div></div></div><br><div ><div class='card' style='box-shadow: 0 4px 70px -18px #7070702e !important;'><div class='card-header'>2º Periodo</div><div class='card-body' ><blockquote class='blockquote mb-0' > "+this.planejamento.periodo2_conteudo+"  </blockquote></div></div></div><br><div ><div class='card' style='box-shadow: 0 4px 70px -18px #7070702e !important;'><div class='card-header'>3º Periodo</div><div class='card-body' ><blockquote class='blockquote mb-0' >  "+this.planejamento.periodo3_conteudo+"</blockquote></div></div></div><br><div ><div class='card' style='box-shadow: 0 4px 70px -18px #7070702e !important;'><div class='card-header'> 4º Periodo</div><div class='card-body' ><blockquote class='blockquote mb-0'> "+this.planejamento.periodo4_conteudo+" </blockquote></div></div></div></div>  </div>";

    },
    imprimir(){
      this.btns = 0;
      window.print();

      setTimeout(() => {
          this.btns = 1;
      }, 1000);

    },

    async listarSegmento() {
      let data = await SegmentoEscolar.obtemTodos();
      this.segmentosALL = data.data;
    },
    async SegmentoUmItem(id) {
      let data = await SegmentoEscolar.obtemUm(id);
      this.planejamento.segmento = data.data.nome;
    },
    async SerieUmItem(id) {
      let data = await SeriesEscolar.obtemUm(id);
      this.planejamento.serie = data.data.nome;
    },
    async listarSerie(seg) {
      let data = await SeriesEscolar.buscaPorSegmento(seg);
      this.serieALL = data.data;
    },
    async listarDisciplina() {
      let data = await DisciplinaEscolar.obtemTodos();
      //this.disciplinaALL = data.data;
      for (const el of data.data) {
        if(el.id != 1){
          this.disciplinaALL.push(el);
        }
      }
    },

    async DisciplinaUmItem(id) {
      let data = await DisciplinaEscolar.obtemUm(id);
      this.planejamento.disciplina = data.data.nome;
    },

    async editarOption(id){
        try {
          let data = await PlanejamentoPedagogico.obtemUm(id);

          this.planejamento.segmento_id = data.data.segmento_id;
          this.SegmentoUmItem(this.planejamento.segmento_id);

          this.listarSerie(data.data.segmento_id);
          this.planejamento.serie_id = data.data.serie_id;
          this.SerieUmItem(this.planejamento.serie_id);

          this.planejamento.disciplina_id = data.data.disciplina_id;
          this.DisciplinaUmItem(this.planejamento.disciplina_id);
          this.planejamento.ano = data.data.ano;

          this.planejamento.periodo1_conteudo = data.data.periodo1_conteudo;
          this.planejamento.periodo2_conteudo = data.data.periodo2_conteudo;
          this.planejamento.periodo3_conteudo = data.data.periodo3_conteudo;
          this.planejamento.periodo4_conteudo = data.data.periodo4_conteudo;

          let mes = '';
          if(data.data.mes<10){
            mes = '0'+data.data.mes;
          }else{
            mes = data.data.mes;
          }

          let dia = '';
          if(data.data.dia<10){
            dia = '0'+data.data.dia;
          }else{
            dia = data.data.dia;
          }

          this.datet =data.data.ano+'-'+mes+'-'+dia;
        } catch (e) {

        }
    },
    async editar() {
      try {
        this.planejamento.id = this.id;
        const data = await PlanejamentoPedagogico.alterar(this.planejamento);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        });
        this.$router.push({name: 'planejamentoPedaAll'});
      }
      catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);

          });
        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }

      }
    },
  },
  async beforeMount() {
    if (this.id == null) {
      this.$router.push({name: 'planejamentoPedaAll'});
    }
    else {
      await this.editarOption(this.id);
      await this.listarSegmento();
      await this.listarDisciplina();

      this.nome_periodos = 0;

      setTimeout(() => {
        this.nome_periodos = 1;
      }, 150);

    }
  }
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }

  ol{
    list-style: decimal;
  }
</style>
